<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <!-- <label>物业名称</label> -->
          <a-input
            class="input"
            v-model="queryParams.keyword"
            placeholder="通知标题/内容 "
            allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <!-- <a-button style="margin-left: 8px" @click="reset">重置</a-button> -->
        <a-button
          type="primary"
          icon="plus"
          ghost
          @click="add"
          v-hasPermission="'notice:add'"
          class="m-l-20"
          >新增
        </a-button>
      </div>
    </div>
    <div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 900 }"
        @change="handleTableChange"
      >
        <template slot="status" slot-scope="text, record">
          <a-tag :color="record.status | dictName('validStatus', 'color')">{{
              record.status | dictName("validStatus")
            }}
          </a-tag>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-icon
            v-hasPermission="'notice:edit'"
            type="edit"
            theme="twoTone"
            twoToneColor="#4a9ff5"
            @click="edit(record)"
            title="修改"
          ></a-icon>
          &nbsp;
          <a-icon
            v-hasPermission="'notice:delete'"
            type="delete"
            theme="twoTone"
            twoToneColor="#FF9900"
            @click="deleteOne(record)"
            style="margin-left: 10px"
            title="删除"
          ></a-icon>
        </template>
      </a-table>
    </div>
    <notice-form ref="noticeForm" @success="success" />
  </a-card>
</template>

<script>
import noticeForm from "./noticeForm.vue";
import { ListMixin } from "@/mixin/list-mixin";
export default {
  name:'NoticeList',
  components: { noticeForm },
  mixins: [ListMixin],
  data() {
    return {
      isExpire: "0",
      listGetUrl: "notice/listByAdmin",
      listMethod: "postJson"
    };
  },
  computed: {
    columns() {
      return [
        {
          title: "ID",
          width: 50,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "通知标题",
          width: 300,
          align: "center",
          dataIndex: "title",
        },
        {
          title: "状态",
          width: 80,
          align: "center",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "生效时间",
          align: "center",
          dataIndex: "validFrom",
          width: 130,
          customRender: (text, record, index) => {
            return (record.validFrom||"").split(" ")[0]+" - "+(record.validTo||"").split(" ")[0];
          },
        },
        {
          title: "通知内容",
          align: "center",
          ellipsis:true,
          dataIndex: "content",
        },
        {
          title: "创建时间",
          align: "center",
          width: 150,
          dataIndex: "createTime"
        },
        {
          title: "操作",
          align: "center",
          width: 100,
          scopedSlots: { customRender: "operation" },
        },
        {}
      ];
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    // 编辑
    edit(row) {
      this.$refs.noticeForm.showModal("edit", row);
    },
    // 删除
    deleteOne(record) {
      let that = this
      this.$confirm({
        title: '删除确认',
        content: '您确定要删除【' + record.title + '】吗？！',
        centered: true,
        onOk() {
          that.$postJson('notice/delete',[record.id]).then(() => {
            that.$message.success('删除成功')
            that.search()
          })
        },
        onCancel() {
          that.selectedRowKeys = []
        }
      })
    },
    success() {
      this.search();
    },
    add() {
      this.$refs.noticeForm.showModal();
    },
  },
};
</script>

<style lang="less" scoped>


</style>
